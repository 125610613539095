import type { SuperHubActionSlotContextValue } from './ActionSlotContext';
import type React from 'react';
import type { useHistory, useLocation } from 'react-router-dom';

import { createContext, useContext } from 'react';

export interface SuperHubContextValue {
  /**
   * Holds reference to the top-level BrowserRouter history instance to allow
   * consumers to navigate the root router to different locations instead.
   */
  browserRouterHistory: ReturnType<typeof useHistory>;
  /**
   * Holds reference to the top-level BrowserRouter location instance to allow
   * consumers to access the current location. This is useful for determining
   * if a browser route has changed from within the HashRouter.
   */
  browserRouterLocation: ReturnType<typeof useLocation>;
  setActionSlot: React.Dispatch<React.SetStateAction<SuperHubActionSlotContextValue>>;
}

const SuperHubContext = createContext<SuperHubContextValue | null>(null);
SuperHubContext.displayName = 'SuperHubContextValue';

/**
 * Contains SuperHub static state that needs to be shared globally. This was
 * largely replaced by ../../../core/store/SuperHub. Do NOT add additional state
 * properties unless they are React-specific properties that cannot be added to
 * the store.
 * @see SuperHubStore
 */
export function useSuperHubContext() {
  const value = useContext(SuperHubContext);
  if (!value) throw new Error('SuperHubContextProvider is not provided');
  return value;
}

export * from './ActionSlotContext';
export * from './Provider';
export default SuperHubContext;
