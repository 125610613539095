import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Box from '@ui/Box';
import Icon from '@ui/Icon';

import BertBot from './bertbot.png';
import classes from './style.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const InfoBert = ({ children, className }: Props) => {
  const bem = useClassy(classes, 'InfoBert');

  return (
    <Box className={bem('&', className)} data-testid="infobert" kind="rule">
      <div className={bem('-avatar')}>
        <img alt="Owlbert avatar" src={BertBot} />
        <span className={bem('-icon')}>
          <Icon name="info-2" />
        </span>
      </div>
      <div className={bem('-content')}>{children}</div>
    </Box>
  );
};

export default React.memo(InfoBert);
