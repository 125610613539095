import React, { useCallback, useRef } from 'react';
import { Transition as RtgTransition } from 'react-transition-group';

interface TransitionProps {
  /**
   * Whether the component should perform the enter transition if it first mounts while in={true}.
   */
  appear?: boolean;
  children: React.ReactNode;
  className?: string;
  /**
   * Keyframes for the enter animation.
   */
  enter: Keyframe[];
  /**
   * Keyframes for the exit animation. Defaults to the reverse of the enter animation.
   */
  exit?: Keyframe[];
  /**
   * Whether to show the animation.
   */
  in: boolean;
  /**
   * Whether to "lazy mount" the component on the first in={true}. After the first enter transition the component
   * will stay mounted, even on "exited", unless you also specify unmountOnExit.
   */
  mountOnEnter?: boolean;
  /**
   * Options for the animation.
   */
  options?: KeyframeAnimationOptions;
  style?: React.CSSProperties;

  /**
   * Whether to unmount the component after it finishes exiting.
   */
  unmountOnExit?: boolean;
}

export const animationTiming = 300; // equivalent to var(--transition-slow)
export const animationEasing = 'cubic-bezier(0.16, 1, 0.3, 1)'; // equivalent to var(--transition-timing)
export const prefersReducedMotion =
  typeof window !== 'undefined' && window.matchMedia('(prefers-reduced-motion: reduce)').matches;

/**
 * Animates the entrance and exit of its children using react-transition-group and Web Animation API.
 * By default, the component will lazy-mount it's child on enter and unmount on exit.
 */
export default function Transition({
  appear = false,
  className,
  children,
  enter,
  exit,
  in: _in, // `in` is a reserved keyword
  mountOnEnter = true,
  unmountOnExit = true,
  options = {
    duration: animationTiming,
    easing: animationEasing,
    fill: 'forwards',
  },
  style,
}: TransitionProps) {
  const nodeRef = useRef<HTMLDivElement>(null);

  const animate = useCallback(
    async (keyframes: Keyframe[]) => {
      if (!nodeRef.current) return;
      if (!unmountOnExit) nodeRef.current.style.display = 'block';
      const animation = nodeRef.current.animate(keyframes, options);
      if (prefersReducedMotion) animation.finish();
      await animation.finished;
    },
    [options, unmountOnExit],
  );

  const animateIn = useCallback(() => {
    if (!nodeRef.current) return;
    animate(enter);
  }, [animate, enter]);

  const animateOut = useCallback(async () => {
    if (!nodeRef.current) return;
    const keyframes = exit || enter.reverse();
    await animate(keyframes);
    if (nodeRef.current) nodeRef.current.style.display = 'none';
  }, [animate, enter, exit]);

  return (
    <RtgTransition
      appear={appear}
      in={_in}
      mountOnEnter={mountOnEnter}
      nodeRef={nodeRef}
      onEnter={animateIn}
      onExit={animateOut}
      timeout={options.duration}
      unmountOnExit={unmountOnExit}
    >
      {() => (
        <div ref={nodeRef} className={className} style={style}>
          {children}
        </div>
      )}
    </RtgTransition>
  );
}
