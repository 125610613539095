import isEmpty from 'lodash/isEmpty';

/**
 * There's a bug in React Hook Form when appending/adding/removing to an array registered with
 * `useFieldArray`, where it will populate the `dirtyFields` object with an
 * empty object as the value for each pristine key.  This function checks for those empty objects.
 *
 * @example
 * { a: {}, b: true, c: {}, d: true } // Is what RHF will return with this bug
 * { b: true, d: true } // Is what it should normally return
 *
 * Original issue I believe is related to this bug:
 * @link https://github.com/react-hook-form/react-hook-form/issues/7197
 * Feature request for aforementioned bug:
 * @link https://github.com/react-hook-form/react-hook-form/issues/9599
 */
function isEmptyObject(value: unknown) {
  return isEmpty(value);
}

/**
 * React Hook Form does not yet give us a convenient way to get the values for
 * all dirty fields whenever a form is submitted or otherwise. We need this so
 * we can only send minimal, partial form data via PUT to our API endpoint.
 *
 * See the discussion link with other devs who've expressed a need for this.
 * Until RHF actually adds this feature to the library, this function allows us
 * to get dirty field values based on RHF's provided `dirtyFields` object and
 * the full `values` object.
 *
 * @link https://github.com/orgs/react-hook-form/discussions/1991#discussioncomment-4593488
 * @example
 * const { formState: { dirtyFields }} = useFormContext();
 *
 * handleSubmit(async data => {
 *   const payload = getDirtyValues(dirtyFields, data);
 *   await save(payload);
 * })
 */
export function getDirtyValues<
  DirtyFields extends Record<string, unknown> | unknown[],
  FormValues extends Record<string | keyof DirtyFields, unknown>,
>(dirtyFields: DirtyFields, formValues: FormValues): Partial<typeof formValues> {
  // If dirtyFields is an array, just return the full array value.
  // RHF will return sparse arrays in dirtyFields, but the API doesn't support
  // updates with sparse arrays. So we need to send all the values
  // even if a value within an array is pristine.
  if (Array.isArray(dirtyFields)) {
    return formValues;
  }

  return Object.entries(dirtyFields).reduce((prev, [key, value]) => {
    // Skip field if dirtyField value is `false`. RHF doesn't return a dirty
    // field with a value of `false`, but check for it anyway.
    if (value === false) {
      return prev;
    }

    // Skip field if there isn't a matching value.
    if (!(key in formValues)) return prev;

    // Skip field if it's an empty array
    if (Array.isArray(value) && !value.length) {
      return prev;
    }

    if (typeof value === 'object' && !Array.isArray(value)) {
      const nestedValue = getDirtyValues(value as DirtyFields, formValues[key] as FormValues);

      // Skip field if it has an empty object
      if (isEmptyObject(nestedValue)) {
        return prev;
      }

      return {
        ...prev,
        [key]: nestedValue,
      };
    }

    return {
      ...prev,
      [key]: formValues[key],
    };
  }, {});
}
