import React, { createContext } from 'react';

export interface SegmentContextValue {
  /**
   * The position and width of the selected SegmentItem in px
   */
  highlightPosition?: { left: number; width: number };
  /**
   * The index of the selected SegmentItem
   */
  selectedIndex?: number;
  setHighlightPosition: React.Dispatch<React.SetStateAction<{ left: number; width: number } | undefined>>;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const SegmentContext = createContext<SegmentContextValue | null>(null);

export function useSegmentContext() {
  const context = React.useContext(SegmentContext);
  if (!context) {
    throw new Error('useSegmentContext must be used within a Segment');
  }
  return context;
}

export default SegmentContext;
