import React from 'react';

import { PLAN_UPGRADE_URL } from '@core/constants/urls';
import useClassy from '@core/hooks/useClassy';

import Button from '@ui/Button';

import PurpleBox from '../PurpleBox';

import changelogImg from './images/changelog-preview.webp';
import customPageImg from './images/custom-page-preview.webp';
import discussionsImg from './images/discussions-preview.webp';
import glossaryImg from './images/glossary-preview.webp';
import guidesImg from './images/guides-preview.webp';
import healthCheckImg from './images/health-check-preview.webp';
import landingPageImg from './images/landing-page-preview.webp';
import recipesImg from './images/recipes-preview.webp';
import reusableContentImg from './images/reusable-content-preview.webp';
import classes from './index.module.scss';

export const featureContentMap = {
  changelog: {
    description: 'Keep your users updated on the latest changes.',
    image: changelogImg,
    label: 'Changelog',
    plan: 'Startup',
  },
  'custom-pages': {
    description: 'Write custom pages for your users.',
    image: customPageImg,
    label: 'Custom Pages',
    plan: 'Startup',
  },
  discussions: {
    description: 'A community forum for your users.',
    image: discussionsImg,
    label: 'Discussions',
    plan: 'Startup',
  },
  glossary: {
    description: 'Display term definitions without having to make space in your docs content.',
    image: glossaryImg,
    label: 'Glossary',
    plan: 'Startup',
  },
  guides: {
    description: 'Write non-API reference content with Guides.',
    image: guidesImg,
    label: 'Guides',
    plan: 'Startup',
  },
  home: {
    description: 'Add a customizable landing page.',
    image: landingPageImg,
    label: 'Landing Page',
    plan: 'Startup',
  },
  'health-check': {
    description: 'Automatically (or manually) alert your users when your API experiences an issue.',
    image: healthCheckImg,
    label: 'Health Check',
    plan: 'Business',
  },
  recipes: {
    description: 'Walk your users through features with code.',
    image: recipesImg,
    label: 'Recipes',
    plan: 'Startup',
  },
  'reusable-content': {
    description: 'Edit Markdown once and update everywhere with reusable content.',
    image: reusableContentImg,
    label: 'Reusable Content',
    plan: 'Business',
  },
};

export interface PayGateBannerProps {
  /**
   * The feature that is gated. Used to determine the content to display in the overlay.
   */
  feature: keyof typeof featureContentMap;
}

export default function PayGateBanner({ feature }: PayGateBannerProps) {
  const bem = useClassy(classes, 'PayGate');
  const content = featureContentMap[feature];
  const isLabelPlural = ['custom-pages', 'discussions', 'guides', 'recipes'].includes(feature);

  return (
    <PurpleBox className={bem('-banner', !!content.image && '-banner_image')}>
      <div className={bem('-body')}>
        {!!content.image && <img alt={content.label} className={bem('-image')} src={content.image} />}
        <h3 className={bem('-title')}>
          {content.label} {isLabelPlural ? 'are' : 'is'} Available on the {content.plan} Plan.
        </h3>
        <p className={bem('-description')}>{content.description}</p>
      </div>
      <div className={bem('-footer')}>
        <Button fullWidth href={PLAN_UPGRADE_URL} kind="contrast" size="md" target="_blank">
          Upgrade
        </Button>
      </div>
    </PurpleBox>
  );
}
