import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Flex from '@ui/Flex';
import Icon from '@ui/Icon';

import styles from './index.module.scss';

const Alert = ({
  title,
  subtitle,
  icon,
  type,
}: {
  icon?: string;
  subtitle: JSX.Element | string;
  title: JSX.Element | string;
  type: 'danger' | 'info' | 'success' | 'warning';
}) => {
  const bem = useClassy(styles, 'Alert');

  return (
    <Flex align="center" className={bem('&', `_${type}`)} data-testid="endpoint-banner" gap="sm" justify="between">
      <Flex align="start" gap="sm" justify="start" layout="col">
        {!!icon && <Icon className={bem(`-icon_${type}`)} name={icon} size="lg" />}
        <Flex gap={0} layout="col">
          <span className={bem('-title')}>{title}</span>
          <span className={bem('-subtitle')}>{subtitle}</span>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Alert;
