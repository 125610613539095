import React, { useCallback, useEffect, useRef, useState } from 'react';

import useClassy from '@core/hooks/useClassy';
import useUniqueId from '@core/hooks/useUniqueId';

import Flex from '@ui/Flex';
import Icon from '@ui/Icon';

import styles from './index.module.scss';

export interface CollapsibleProps {
  /**
   * Content to be shown or hidden.
   */
  children?: React.ReactNode;
  className?: string;

  /**
   * Handler gets called whenever collapsed state changes.
   */
  onToggle?: (collapsed: boolean) => void;

  /**
   * Opened state.
   */
  opened?: boolean;
  style?: React.CSSProperties;

  /**
   * Renders a summary toggle that when clicked flips the collapsed state.
   */
  summary?: React.ReactNode;

  /**
   * Content is rendered inside a `<div>` by default. Use this prop to render it
   * inside a different tag instead.
   */
  tag?: React.ElementType;
}

function Collapsible({
  children,
  className,
  opened = false,
  style,
  summary,
  onToggle,
  tag: Tag = 'div',
}: CollapsibleProps) {
  const bem = useClassy(styles, 'Collapsible');
  const uid = useUniqueId('Collapsible');
  const isEmpty = !React.Children.count(children);

  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(opened ? 'auto' : '0px');
  const [openedState, setOpenedState] = useState(opened);

  // Handles action events on the "summary" element and invokes the prop handler
  // to notify consumers of state changes.
  const handleToggle = useCallback(() => {
    setOpenedState(!openedState);
    onToggle?.(!openedState);
  }, [onToggle, openedState]);

  // Calculate the total list height whenever child content reports any changes
  // to its dimensions.
  useEffect(() => {
    const ro = new ResizeObserver(([entry]) => {
      const contentRect = entry.target.getBoundingClientRect();
      const contentParentRect = entry.target.parentElement?.getBoundingClientRect();
      if (!contentRect || !contentParentRect) return;

      // Try to incorporate any top margin offsets here. We unfortunately can't
      // do much to incorporate bottom margins in contrast, which is a
      // limitation. Padding is preferred if bottom spacing is required.
      const contentOffset = contentRect.top - contentParentRect.top;
      setHeight(`${contentRect.height + contentOffset}px`);
    });

    if (contentRef.current) {
      ro.observe(contentRef.current);
    }

    return () => ro.disconnect();
  }, [contentRef]);

  // Sync the "collapsed" prop with our source of truth state.
  useEffect(() => {
    setOpenedState(opened);
  }, [opened]);

  return (
    <>
      {!!summary && (
        <Flex
          align="center"
          aria-controls={uid('group')}
          aria-expanded={openedState ? 'true' : 'false'}
          className={bem('-summary', openedState && '-summary_opened', isEmpty && '-summary_empty')}
          gap="xs"
          justify="start"
          onClick={handleToggle}
          tabIndex={isEmpty ? -1 : 0}
          tag="button"
          type="button"
        >
          <Icon className={bem('-summary-icon')} name="chevron-right" />
          {summary}
        </Flex>
      )}
      <div
        aria-hidden={openedState ? 'false' : 'true'}
        className={bem('&', openedState && '_opened')}
        id={uid('group')}
        role="group"
        style={{ height: openedState ? height : '0px' }}
      >
        <Tag ref={contentRef} className={bem(className, '-content', openedState && '-content_opened')} style={style}>
          {children}
        </Tag>
      </div>
    </>
  );
}

export default Collapsible;
