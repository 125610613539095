import { createContext, useContext } from 'react';

export type SuperHubActionSlotContextValue = HTMLDivElement | null;

export const SuperHubActionSlotContext = createContext<SuperHubActionSlotContextValue | undefined>(undefined);

/**
 * References an element inside the SuperHub island that holds actionable things
 * like CTAs, Edit, Save, etc. Is made available to some (but not all) island
 * layouts so outside components can portal render their own CTAs into it.
 */
export function useSuperHubActionSlotContext() {
  const value = useContext(SuperHubActionSlotContext);
  if (value === undefined) throw new Error('SuperHubContextProvider is missing');
  return value;
}
