import React, { useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import SuperHubContext, { SuperHubActionSlotContext } from '.';

export interface SuperHubContextProviderProps {
  children: React.ReactNode;
}

export function SuperHubContextProvider({ children }: SuperHubContextProviderProps) {
  const browserRouterHistory = useHistory();
  const browserRouterLocation = useLocation();

  const [actionSlot, setActionSlot] = useState<HTMLDivElement | null>(null);

  return (
    <SuperHubContext.Provider
      value={useMemo(
        () => ({
          browserRouterHistory,
          browserRouterLocation,
          setActionSlot,
        }),
        [browserRouterHistory, browserRouterLocation],
      )}
    >
      <SuperHubActionSlotContext.Provider value={actionSlot}>{children}</SuperHubActionSlotContext.Provider>
    </SuperHubContext.Provider>
  );
}
