import type { WebhooksSetupContextValue } from '../WebhooksSetupContext';
import type { $TSFixMe } from '@readme/iso';

import React, { useContext, useMemo, useEffect, useRef, useCallback } from 'react';

import useAmplitude, { AMPLITUDE_EVENT, AMPLITUDE_EVENT_PROPERTY } from '@core/hooks/useAmplitude';
import useClassy from '@core/hooks/useClassy';
import useDebounced from '@core/hooks/useDebounced';
import { useReferenceStore } from '@core/store';
import spreadLineNumbers from '@core/utils/spreadLineNumbers';

import Button from '@ui/Button';
import CodeSnippet from '@ui/CodeSnippet';
import CopyToClipboard from '@ui/CopyToClipboard';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import LanguagePicker from '@ui/LanguagePicker';
import Menu, { MenuItem, MenuHeader } from '@ui/Menu';
import Toggle from '@ui/Toggle';

import { WebhooksSetupContext } from '../WebhooksSetupContext';

import styles from './style.module.scss';

interface Props {
  hideLanguagePicker?: boolean;
  highlight?: number | string;
  isLanguagePickerMinimal?: boolean;
  isNewSetup?: boolean;
  isQuickstart?: boolean;
}

function WebhookCodeSnippet({
  hideLanguagePicker = false,
  highlight,
  isLanguagePickerMinimal = false,
  isNewSetup = false,
  isQuickstart = false,
}: Props) {
  const elementRef = useRef<HTMLDivElement | null>(null);
  const bem = useClassy(styles, 'WebhookCodeSnippet');
  const { track } = useAmplitude();

  const language = useReferenceStore(s => s.language.language as NonNullable<typeof s.language.language | 'aws'>);
  const {
    clients = [],
    client: currentClient,
    clientKey,
    clientSnippetOptions,
    setClientKey,
    setClientSnippetOptions,
    snippet = '',
  } = useContext(WebhooksSetupContext) as WebhooksSetupContextValue;

  const snippetOptions = useMemo(() => {
    const ranges: $TSFixMe = spreadLineNumbers(highlight);
    return {
      dark: true,
      highlightMode: true,
      ranges,
      readOnly: true,
    };
  }, [highlight]);

  useEffect(() => {
    if (highlight && elementRef.current) {
      const isMultiLine = typeof highlight === 'string' && highlight.includes('-');
      elementRef.current
        .querySelector(`.cm-highlight${isMultiLine ? ':last-of-type' : ''}`)
        ?.scrollIntoView({ block: 'nearest' });
    }
  }, [highlight]);

  const languagePicker = useMemo(
    () => <LanguagePicker className={bem('-languagePicker')} kind={isLanguagePickerMinimal ? 'dropdown' : 'tabs'} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bem, language, isLanguagePickerMinimal],
  );

  const handleProvisionKeysToggle = useCallback(
    event => {
      setClientSnippetOptions({
        createKeys: event.target.checked,
      });
    },
    [setClientSnippetOptions],
  );

  const handleCopy = useCallback(
    target => {
      track(AMPLITUDE_EVENT.PD_WEBHOOK_CODE_COPY, {
        language: target,
        type: isQuickstart ? AMPLITUDE_EVENT_PROPERTY.QUICKSTART : AMPLITUDE_EVENT_PROPERTY.SETUP,
      });
    },
    [isQuickstart, track],
  );

  const handleCodeSelection = useDebounced(target => {
    track(AMPLITUDE_EVENT.PD_WEBHOOK_CODE_SELECT, {
      language: target,
      type: isQuickstart ? AMPLITUDE_EVENT_PROPERTY.QUICKSTART : AMPLITUDE_EVENT_PROPERTY.SETUP,
    });
  }, 3000);

  const isAWS = language === 'aws';

  const header = useMemo(
    () => (
      <header className={bem('-header')}>
        {!hideLanguagePicker && !isLanguagePickerMinimal && languagePicker}
        <div className={bem(!isLanguagePickerMinimal && '-subheader', hideLanguagePicker && '-subheader_hidePicker')}>
          <Flex align="center" gap="xs" justify="start">
            {!hideLanguagePicker && !!isLanguagePickerMinimal && languagePicker}

            {isAWS ? (
              <span className={bem('-client-label')}>Amazon API Gateway</span>
            ) : clients.length && clients.length > 1 ? (
              <Dropdown clickInToClose justify="start" sticky>
                <Button className={bem('-dropdown-btn')} dropdown ghost kind="secondary" size="xs">
                  {currentClient?.title}
                </Button>
                <Menu>
                  <MenuHeader className={bem('-menu-header')}>Library</MenuHeader>
                  {clients.map((client, i) => (
                    <MenuItem
                      key={`${client.key}_${i}`}
                      active={currentClient === client}
                      onClick={() => setClientKey(client.key)}
                    >
                      {client.title}
                    </MenuItem>
                  ))}
                </Menu>
              </Dropdown>
            ) : (
              <span className={bem('-client-label')}>{clients[0]?.title}</span>
            )}

            <CopyToClipboard
              className={bem('-copy')}
              onCopy={() => handleCopy(isAWS ? clientKey : language)}
              shift="start"
              text={snippet}
            />
          </Flex>

          {/* For AWS API Gateway, we have add'tl AWS specific options for code snippets */}
          {!!isAWS && (
            <Flex align="center" className={bem('-library-options')} gap="xs" layout="col">
              <Flex align="center" className={bem('-option-row')} gap="xs" justify="between">
                <span>Lambda Runtime</span>
                <Dropdown clickInToClose sticky trigger="click">
                  <Button className={bem('-lambda-button')} dropdown kind="secondary" size="xs">
                    {currentClient?.lambdaRuntime}
                  </Button>
                  <Menu>
                    {clients.map((client, i) => (
                      <MenuItem key={`lambda_${client.key}_${i}`} onClick={() => setClientKey(client.key)}>
                        {client.lambdaRuntime}
                      </MenuItem>
                    ))}
                  </Menu>
                </Dropdown>
              </Flex>
              <Flex align="center" className={bem('-option-row')} gap="xs" justify="between">
                <span>Provision keys for new users</span>
                <Toggle
                  checked={!!clientSnippetOptions?.createKeys}
                  className={bem('-provision-toggle')}
                  onChange={handleProvisionKeysToggle}
                  type="toggle"
                />
              </Flex>
            </Flex>
          )}
        </div>
      </header>
    ),
    [
      bem,
      clients,
      clientKey,
      clientSnippetOptions,
      currentClient,
      handleCopy,
      handleProvisionKeysToggle,
      hideLanguagePicker,
      isAWS,
      isLanguagePickerMinimal,
      language,
      languagePicker,
      setClientKey,
      snippet,
    ],
  );

  return (
    <div ref={elementRef} className={bem('&', isNewSetup && '_newSetup')}>
      <CodeSnippet
        className={bem('-snippet')}
        code={snippet}
        header={header}
        language={isAWS && clientKey ? clientKey : language} // For 'aws', we want to use clientKey as the language
        onClick={() => handleCodeSelection(isAWS ? clientKey : language)}
        options={snippetOptions}
      />
    </div>
  );
}

export default WebhookCodeSnippet;
