import React from 'react';
import ShadowRoot from 'react-shadow';

import useEnvInfo from '@core/hooks/useEnvInfo';

import useDocumentStylesheets from './useDocumentStylesheets';

interface SafeStylesProps {
  children: React.ReactNode;
  /**
   * Array of stylesheets to ignore, identified by `title` attribute.
   */
  exclude?: string[];
  /**
   * Allow or deny access to the child node(s) from JavaScript outside the <SafeStyles> shadow boundary
   */
  noJS?: boolean;
}

/**
 * Use this component to encapsulate child nodes in shadow DOM.
 * This isolates the child nodes from global styles, then it
 * cherry-picks inline and linked global styles that haven't
 * been specifically excluded and applies them to the shadow root
 */
function SafeStyles({
  className,
  children,
  noJS = false,
  exclude = [],
}: React.HTMLProps<HTMLElement> & SafeStylesProps) {
  const { isTest } = useEnvInfo();
  const { inlineStylesheets, linkedStylesheets, isLoaded } = useDocumentStylesheets({ exclude });

  /**
   * If we're in a test environment, don't use shadow DOM
   * because it's not supported by react testing library.
   * https://github.com/testing-library/dom-testing-library/issues/413
   */
  if (isTest) return <>{children}</>;

  return (
    <ShadowRoot.div className={className} mode={noJS ? 'closed' : 'open'} styleSheets={inlineStylesheets}>
      {/**
       * Copying linked stylesheets here will dupe requests
       * but they should be cached in browser memory from the initial page load.
       */}
      {linkedStylesheets}

      {/*
      We must be careful to only load children AFTER all stylesheets have been
      loaded in order to ensure children components and their elements are
      initially mounted with their appropriate styles and positions.
        */}
      {!!isLoaded && children}
    </ShadowRoot.div>
  );
}

export default SafeStyles;
